import Bus from '~/utils/eventBus'

function SiteMenu() {
  this.selected = []
  this.deleteBlockVisible = false
  this.columnIndex = 0
  this.columnChildIndex = -1
  this.$bus = new Bus()
}

SiteMenu.prototype.open = function (menu) {
  const { key, target } = menu
  const current = this.selected[this.selected.length - 1] || {}
  if(key === current.key && target === current.target){
    return false
  }
  const index = this.selected.findIndex(item => item.key === key)
  if (current.key === key) {
    this.selected.splice(index, 1, menu)
  } else {
    this.selected.push(menu)
  }
}

SiteMenu.prototype.close = function (key) {
  if (!key) {
    this.selected = []
    return
  }
  const index = this.selected.findIndex(item => item.key === key)
  if (index > -1) {
    this.selected.splice(index, 1)
  }
}

SiteMenu.prototype.setColumnIndex = function (index) {
  this.columnIndex = index
}

SiteMenu.prototype.setColumnChildIndex = function (index) {
  this.columnChildIndex = index
}


SiteMenu.prototype.openMenuShowImage = function (menu, eventKey) {
  this.open(menu)
  this.showImageWithKey(eventKey)
}
/*
* 根节点下的图片显示
* */
SiteMenu.prototype.closeShowImage = function (eventKey) {
  this.close(null)
  this.showImageWithKey(eventKey)
}

SiteMenu.prototype.openDeleteBlockDialog = function () {
  this.deleteBlockVisible = true
}

SiteMenu.prototype.closeDeleteBlockDialog = function () {
  this.deleteBlockVisible = false
}

/*
* 只显示图片
* */
SiteMenu.prototype.showImageWithKey = function (eventKey) {
  setTimeout(()=>{
    this.$bus && this.$bus.$emit('show-bg-'+ (eventKey || ''))
  },0)
}

const siteMenu = new SiteMenu()


export default siteMenu
