import { DefaultDevice } from '~/settings/deviceSetting'
export const state = () => ({
  currentRichTextId: '',
  currentModel: {
    id: ''
  },
  editMode: false,
  device: DefaultDevice,
  pcDeviceWidth: 0
})

export const mutations = {
  SET_WIDGET_TAG(state, data) {
    state.widgetTag = data
  },
  SET_CURRENT_RICH_TEXT_ID(state, id) {
    state.currentRichTextId = id
  },
  SET_EDIT_MODE (state, bool) {
    state.editMode = Boolean(bool)
  },
  SET_DEVICE(state, device) {
    state.device = device
  },

  SET_PC_DEVICE_WIDTH(state, w) {
    state.pcDeviceWidth = w
  },

  SET_DEVICE_WIDTH(state, d) {
    state.deviceWidth = d
  },

  SET_CURRENT_MODEL(state, model) {
    state.currentModel = model
  }
}
export const actions ={
}
