import Vue from 'vue'

import {
  Input,
  InputNumber,
  Button,
  Checkbox,
  Select,
  Option,
  // Form,
  Row,
  Col,
  Icon,
  Radio,
  Tooltip,
  ColorPicker,
  Loading
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/display.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

// 设置语言
locale.use(lang)
Vue.prototype.$ELEMENT = {
  zIndex: 3000,
}
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.use(Button)
Vue.use(ColorPicker)
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Checkbox)
// Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)
Vue.use(Radio)
Vue.use(Option)
Vue.use(Loading)


export const elementTags = [
  Input.name,
  Button.name,
  ColorPicker.name,
  Tooltip.name,
  Select.name,
  Input.name,
  InputNumber.name,
  Checkbox.name,
  // Form.name,
  Row.name,
  Col.name,
  Icon.name,
  Radio.name,
  Option.name
]
