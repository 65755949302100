import { DeviceEnum } from '~/enums/deviceEnum'

const getters = {
  menuKeys: state => state.menu.menuKeys,
  recentColors: state => state.theme.recentColors,
  isMobile: state => state.editor.device === DeviceEnum.MOBILE,
  isDesktop: state => state.editor.device === DeviceEnum.DESKTOP
}

export default getters
