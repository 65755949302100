import { promiseToEditPrices } from '~/utils/prices'
import { mergePromiseToSame } from '~/utils/api'

const Api = {
  StoreVirtualItems: '/api/v2/store/virtual-item/get',
  MerchantVirtualItems: '/api/v2/merchant/virtual-item/get',
  VirtualItem: '/api/v2/store/virtual-item/detail', // /store/virtual-item
}
export default (request) => {
  return {
    //  虚拟物品列表-查询
    getVirtualItems: (params, options) => {
      const { editing } = options
      params && (params.enabled = 1)
      return promiseToEditPrices(
        mergePromiseToSame(
          editing ? Api.MerchantVirtualItems : Api.StoreVirtualItems,
          params,
          options,
          request
        )
      )
    },
    //  虚拟物品详情-查询
    getVirtualItem: (params, options) => {
      return promiseToEditPrices(
        request.get(Api.VirtualItem, { params, ...options })
      )
    },
  }
}
