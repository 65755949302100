import { once } from "~/utils/dom";

export function postMessage(message) {
  window.parent.postMessage(message, '*')
}

export function sendMessage(detail) {
  return new Promise((resolve, reject) => {
    once(window, 'message', (event) => {
      const origin = event.origin || event.originalEvent.origin
      if (origin) {
        if (event.data?.type === detail.type) {
          resolve(event.data)
        }
      }
    })
    postMessage(detail)
  })
}
